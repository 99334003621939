.rooms-form {
  width: 100%;
  max-width: 580px;
  margin: 0 auto;

  &__btn {
    margin: 0 auto !important;
    width: 100%;
    max-width: 250px;

    display: flex !important;

    &.rooms-form__btn--leftImg {
      &::after {
        content: '';
        display: flex;
        width: 10px;
        height: 10px;

        position: absolute;
        top: 12px;
        left: 12px;

        background-image: url('../../images/arrow-l.svg');
        background-repeat: no-repeat;
        background-position: center;

        transition: 0.3s all ease-in-out;
      }

      &:hover {
        &::after {
          transform: translateX(5px);
        }
      }
    }
  }

  .rooms-form__btn + .rooms-form__btn {
    margin-top: 15px !important;
  }

  &__heading {
    text-align: center;

    &-title {
      margin: 0 0 20px 0;

      font-size: 30px;
      font-weight: 500;
      line-height: 34px;
      color: $main-font-color;
      text-align: center;
    }

    &-subtitle {
      margin: 0 0 30px 0;

      font-size: 16px;
      font-weight: 400;
      font-style: normal;
      line-height: 19px;
      color: $main-font-color;
      text-align: center;
      padding: 0 28px;
    }
  }

  &__body {
    margin: 0 0 30px 0;
  }

  &__item {
    margin: 0 0 10px 0;

    display: flex;

    &-left {
      flex: auto;
      padding: 15px 20px;

      display: flex;
      align-items: flex-start;
      justify-content: space-between;

      border: 1px solid #e0dfdf;
      border-radius: 5px;
      background: #fff;
    }

    &-wrap {
      display: flex;
      flex-direction: column;
    }

    &-right {
      min-width: 225px;
      margin: 0 0 0 6px;
      padding: 7px 20px;

      border: 1px solid #e0dfdf;
      border-radius: 5px;
      background: #fff;

      flex: 1;
      display: flex;
      align-items: center;
      justify-content: space-between;

      & + .rooms-form__item-right {
        margin: 6px 0 0 6px;
      }
    }

    &-name {
      font-size: 18px;
      line-height: 21px;
      color: #525252;
      font-weight: 500;
    }

    &-area {
      margin: 0 10px 0 0 !important;

      & > div > input {
        width: 50px;
        height: 30px;
        padding: 0;

        text-align: center;

        border: 1px solid #c4c4c4;
        border-radius: 5px;
      }
    }
  }
}

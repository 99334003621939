.toilet {
  &-content {
    width: 100vw;
    height: 100vh;

    background-size: cover;
    background-image: url('../../images/toilet/bg.webp');
    background-repeat: no-repeat;
    background-position: center;

    position: relative;
  }

  &-floor__img {
    width: 100%;
    height: 100%;

    position: absolute;
    top: 0;
    left: 0;

    object-fit: cover;

    &.hide {
      display: none;
    }
  }

  &-wall__img {
    width: 100%;
    height: 100%;

    position: absolute;
    top: 0;
    left: 0;

    object-fit: cover;

    &.hide {
      display: none;
    }
  }

  &-ceil__img {
    width: 100%;
    height: 100%;

    position: absolute;
    top: 0;
    left: 0;

    object-fit: cover;

    &.hide {
      display: none;
    }
  }

  .select {
    position: relative;

    & .btn {
      width: 24px !important;
      height: 24px !important;
      min-width: 24px !important;
      padding: 0;

      border: 5px solid #3f51b5;
      border-radius: 50%;
      box-shadow: inset 0 0 2px 0 rgba(0, 0, 0, 0.3);
      background-color: #fff;

      z-index: 999;

      cursor: pointer;
      animation: 3s pulse infinite;
    }
  }
}

.select {
  padding: 10px 10px 10px 5px;

  background: rgba(0, 0, 0, 0.55);
  border-radius: 10px;
  backdrop-filter: blur(3px);

  display: flex;
  align-items: center;

  z-index: 1001;

  &-bage {
    display: flex;
    margin: 0 0 0 10px;
    color: #fff;
    text-align: center;
    align-items: center;
  }
}

.room {
  &-content {
    width: 100vw;
    height: 100vh;

    background-size: cover;
    background-image: url('../../images/room/bg.webp');
    background-repeat: no-repeat;
    background-position: center;

    position: relative;
  }

  &-floor__img {
    width: 100%;
    height: 100%;

    position: absolute;
    top: 0;
    left: 0;

    object-fit: cover;

    &.hide {
      display: none;
    }
  }

  &-wall__img {
    width: 100%;
    height: 100%;

    position: absolute;
    top: 0;
    left: 0;

    object-fit: cover;

    &.hide {
      display: none;
    }
  }

  &-ceil__img {
    width: 100%;
    height: 100%;

    position: absolute;
    top: 0;
    left: 0;

    object-fit: cover;

    &.hide {
      display: none;
    }
  }

  .select {
    position: relative;

    & .btn {
      width: 24px !important;
      height: 24px !important;
      min-width: 24px !important;
      padding: 0;

      border: 5px solid #3f51b5;
      border-radius: 50%;
      box-shadow: inset 0 0 2px 0 rgba(0, 0, 0, 0.3);
      background-color: #fff;

      z-index: 999;

      cursor: pointer;
      animation: 3s pulse infinite;
    }
  }
}

.MuiPopover-paper {
  color: #fff !important;
  padding: 10px 20px;
  border-radius: 4px !important;

  left: -13px;
  top: 12px;

  background: rgba(0, 0, 0, 0.5) !important;
  -webkit-backdrop-filter: blur(5px) !important;
  backdrop-filter: blur(5px) !important;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2) !important;

  z-index: 100;

  & .legend {
    color: #fff !important;
  }

  & .MuiIconButton-label {
    color: #3f51b5 !important;
  }
}

@keyframes pulse {
  0% {
    transform: scale(0.9);
    box-shadow: 0 0 0 0 rgba(64, 81, 180, 0.7);
  }

  50% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(64, 81, 180, 0);
  }

  100% {
    transform: scale(0.9);
  }
}

/*.........ROBOTO FONT......... */
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap');

/*.........DEFAULT CSS......... */
*,
*::after,
*::before {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-size: 16px;
  font-family: Roboto, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.container {
  width: 100%;
  max-width: 1140px;
  margin: 0 auto;
  padding: 0 10px;
}

/*.........VARIABLES......... */
@import 'variables';

/*.........COMPONENTS......... */
@import './components/params-form';
@import './components/rooms-form';
@import './components/image-card';
@import './components/counter';
@import './components/totals-card';
@import './components/sidebar';
@import './components/select-dropdown';
@import './components/navbar-price';

/*.........PAGES CSS......... */
@import './pages/params';
@import './pages/room';
@import './pages/toilet';
@import './pages/kitchen';
@import './pages/result';

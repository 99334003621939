.totals-card {
  width: 100%;
  max-width: 700px;
  margin: 0 auto;

  padding: 20px 30px;

  display: flex;
  align-items: center;

  &__img {
    width: 100%;
    max-width: 40px;
    margin: 0 15px 0 0;

    object-fit: cover;
  }

  &__left {
    padding: 0 20px 0 0;

    display: flex;
    align-items: center;
    justify-content: space-between;
    flex: 1;

    border-right: 2px solid whitesmoke;

    &-text {
      display: flex;
      align-items: center;
    }

    & p {
      margin: 0;

      font-size: 20px;
      font-weight: 500;
    }
  }

  &__right {
    display: flex;
    align-items: center;

    margin: 0 0 0 15px;

    & p {
      margin: 0;

      font-size: 16px;
      font-weight: 500;
    }
  }
}

.params-form {
  width: 100%;
  max-width: 580px;
  margin: 0 auto;

  &__heading {
    text-align: center;

    &-title {
      margin: 0 0 30px 0;

      font-style: normal;
      font-weight: 700;
      font-size: 45px;
      line-height: 58px;
      color: $main-font-color;
      text-align: center;
    }

    &-subtitle {
      margin: 0 0 30px 0;

      font-size: 16px;
      font-weight: 500;
      font-style: normal;
      line-height: 19px;
      color: $secondary-font-color;
      text-align: center;
      padding: 0 28px;
    }
  }

  &__body {
    margin: 0 0 35px 0;

    display: flex;
    flex-direction: column;

    &-item {
      margin: 0 0 15px 0 !important;
    }
  }

  &__btn {
    display: flex !important;
    margin: 0 auto !important;
  }
}
